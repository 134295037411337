import { AbstractApiModel } from '@ng-cloud/badger-core/models/abstract-api-model';
import { Point, Point3d } from '@ng-cloud/badger-core';
import { Sku } from '@ng-cloud/badger-core/models/sku';

export class TopStock extends AbstractApiModel<TopStock> {
  detectedGTIN: string;
  clientModuleId: string;
  productId: number;
  storeId: number;
  scanEventId: number;
  storeLocation: Point3d[];
  shelfLocation: Point[];
  barcodeLocation: Point[];
  imageFileUrl: string;
  imageFileStatus: string;
  skus: Sku[];

  deserialize(json: any): this {
    this.detectedGTIN = json.detected_gtin;
    this.clientModuleId = json.client_module_id;
    this.productId = json.product_id;
    this.storeId = json.store_id;
    this.scanEventId = json.scan_event_id;
    this.storeLocation = json.store_polygon;
    this.shelfLocation = json.shelf_polygon;
    this.barcodeLocation = json.barcode_polygon;
    this.imageFileUrl = json.image_file?.image_url;
    this.imageFileStatus = json.image_file?.status;
    if (json.skus) {
      json.skus.forEach((sku) => {
        this.skus.push(new Sku().deserialize(sku));
      });
    }
    return super.deserialize(json);
  }

  serialize(): any {
    return Object.assign(super.serialize(), {
      id: this.id,
      detected_gtin: this.detectedGTIN,
      client_module_id: this.clientModuleId,
      product_id: this.productId,
      store_id: this.storeId
    });
  }
}
